import React from 'react';
import DefaultLayout from '../components/DefaultLayout';
import { Link } from 'gatsby';

interface Props {}

const ThankYou: React.FC = () => {
  return (
    <DefaultLayout>
      <div className='four-oh-four container max-w-xs mx-auto mt-10'>
        <h1 className='text-4xl text-primary-base mb-16 font-bold text-left'>Thank you for signing up</h1>
        <p className='mb-16 text-justify'>
          In order to get access to this content, you need to confirm your email.
        </p>
        <p className='mb-16 text-justify'>
          Just go to your inbox, open the email from Michael Argento, and click the link within to confirm your email
          address.
        </p>
        <p className='mb-16 text-justify'>
          P.S. If you don’t see a confirmation email, please check your spam/junk/quarantine folders and mark it as not
          spam.
        </p>
        <Link className='text-primary-base text-center mx-auto block' to='/'>
          Go back to the Home Page
        </Link>
      </div>
    </DefaultLayout>
  );
};

export default ThankYou;
